export default defineNuxtRouteMiddleware((to, from) => {
  const userStore = useUserStore()

  // Wait for the user to be loaded
  userStore.$subscribe(() => {
    if (!userStore.isClinicAdmin) {
      showToast(
        'error',
        'Acesso Negado',
        'Só os administradores de clínica têm acesso a esta página.',
      )
      console.error('Forbidden access to an admin page', from, to)
      return navigateTo('/')
    }
  })
})
